import { Button } from './components/styles/Button.styled'
import { ResultPage } from './components/styles/Enrollment.styled'

const Failure = () => {
    return (
        <ResultPage>
            <div className="text-center">
                <h1 className="mb-3">Oops!</h1>
                <p>Your deposit payment did not go through. Please login to MyAccount to pay the deposit. Once paid, your enrollment will be processed.</p>
                <div className="mt-5">
                    <Button
                        onClick={() => {
                            document.location.href = `https://myaccount.cleanskyenergy.com/`
                        }}>
                        Go to MyAccount
                    </Button>
                </div>
            </div>
        </ResultPage>
    )
}

export default Failure