import { Modal } from "react-bootstrap"
import { Button } from "./styles/Button.styled";
import { ErrorModalBody } from "./styles/Enrollment.styled";
import { useTranslation } from "react-i18next";

export const ErrorModal = ({
    content,
    handleClose,
    isTx
}: {
    content: any;
    handleClose: Function;
    isTx: undefined|boolean;
}) => {
    const { t } = useTranslation();
    if (!content) return null;
    return (
        <Modal size="lg" centered animation={false} show={true} onHide={() => handleClose()}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <ErrorModalBody>
                    {
                        content.title &&
                        <h2>{content.title}</h2>
                    }
                    {
                        content.desc ?
                        <p>{content.desc}</p> :
                        <>
                            <br/>
                            <p>{t('Please call us at')} {isTx ? <a href="tel:8887335557">(888) 733-5557</a> : <a href="tel:8883556205">(888) 355-6205</a>} {t('Error modal footer text')}</p>
                        </>
                    }
                    {
                        (content.button && content.button === 'login') ?
                        <Button withArrow onClick={() => {
                            window.location.href = 'https://myaccount.cleanskyenergy.com/';
                        }}>MyAccount</Button> :
                        <Button onClick={() => handleClose()}>{content.buttonText ? content.buttonText : t('Close')}</Button>
                    }
                </ErrorModalBody>
            </Modal.Body>
        </Modal>
    )
}