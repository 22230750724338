import { Trans, useTranslation } from "react-i18next";
import { EnrollmentResultContent } from "../../styles/Enrollment.styled";
import { errorMsg } from "../errorMsg";
import { Button } from "../../styles/Button.styled";
import { AgentFraudScreen } from "../AgentWebPortal/AgentFraudScreen";
import { adminPortalAgent } from "../../../config";

export const PendingVerification = ({ status, isAgent, next_step }: {
    status: string;
    isAgent: boolean;
    next_step: string;
}) => {
    const { t, i18n } = useTranslation('enrollment')

    if (status === '30.E09') {
        return (
            <>
                {isAgent && <AgentFraudScreen />}
                {
                    next_step === "autopay" ?
                    <EnrollmentResultContent>
                        <div className="text-center">
                            <h1 className="mb-3">{t('We are Almost There!')}</h1>
                            <p>
                                <b>{t('Your application for service with CleanSky Energy has been placed on hold, pending verification of your information. Your service will not start until such time as your information can be validated.')}</b>
                                <br/>
                                <br/>
                                <Trans i18nKey="enrollment:fraudWarningDescAutopay1" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}>
                                </Trans>
                            </p>
                            <p>
                                <b>{t('fraudWarningDescAutopay2')}</b>
                            </p>
                            <p>
                                {t('fraudWarningDescAutopay3')}
                            </p>
                            <div className="mt-5">
                                {isAgent?<Button
                                    withArrow
                                    onClick={() => {
                                        window.location.href = `${adminPortalAgent}`
                                    }}>
                                    {t('Back to Enrollment Portal')}
                                </Button>:
                                <Button
                                    withArrow
                                    onClick={() => {
                                        window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                                    }}>
                                    {t('Back to Homepage')}
                                </Button>}
                            </div>
                        </div>
                    </EnrollmentResultContent> :
                    <EnrollmentResultContent>
                        <div className="text-center">
                            <h1 className="mb-3">{t('We are Almost There!')}</h1>
                            <p>
                                <b>{t('Your application for service with CleanSky Energy has been placed on hold, pending verification of your information. Your service will not start until such time as your information can be validated.')}</b> 
                                <br/>
                                <br/>
                                <Trans i18nKey="enrollment:fraudWarningDescDeposit1" components={{11: <a href="tel:877-424-1003" />, 12: <a href="mailto:Verify@CleanSkyEnergy.com" />}}>
                                </Trans>
                            </p>
                            <p>
                                <b>{t('fraudWarningDescDeposit4')}</b> 
                                {t('fraudWarningDescDeposit2')}
                            </p>
                            <p>
                                {t('fraudWarningDescDeposit3')}
                            </p>
                            <div className="mt-5">
                                {isAgent?<Button
                                    withArrow
                                    onClick={() => {
                                        window.location.href = `${adminPortalAgent}`
                                    }}>
                                    {t('Back to Enrollment Portal')}
                                </Button>:
                                <Button
                                    withArrow
                                    onClick={() => {
                                        window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                                    }}>
                                    {t('Back to Homepage')}
                                </Button>}
                            </div>
                        </div>
                    </EnrollmentResultContent>
                }
            </>
        )
    }

    return (
        <EnrollmentResultContent>
            <div className="text-center">
                <h1 className="mb-3">{errorMsg[status].headline}</h1>
                <p>{errorMsg[status].paragraph}</p>
                {
                    errorMsg[status].paragraph2 &&
                    <p>{errorMsg[status].paragraph2}</p>
                }
                {
                    (errorMsg[status].buttonType && errorMsg[status].buttonType === 'myaccount') ?
                    <div className="mt-5">
                        <Button
                            withArrow
                            onClick={() => {
                                window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                            }}>
                            Back to Homepage
                        </Button>
                    </div> :
                    <div className="mt-5">
                        <Button
                            onClick={() => {
                                window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                            }}>
                            Back to Homepage
                        </Button>
                    </div>
                }
            </div>
        </EnrollmentResultContent>
    )
}
