import React, { useState, useCallback,useEffect } from 'react';

interface Props {
  openScripts: boolean,
  setOpenScript: any,
  scriptData: any
}

const ScriptModal: React.FC<Props> = ({ openScripts,setOpenScript,scriptData }) => {

  return (
    <>
        {/* <DraggableModal visible={openScripts} 
          footer={null}
          title={<div style={{fontSize:"20px",fontWeight:700}}>{scriptData?.scriptHead}</div>} 
          width={600} initialHeight={600} onOk={()=>setOpenScript(true)} onCancel={()=>setOpenScript(false)}>
            {scriptData?.script}
        </DraggableModal> */}
    </>
  )
}

export default ScriptModal