import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { EnrollmentContext } from "../../../enrollment";
import { EnrollmentResultContent } from "../../styles/Enrollment.styled";
import { Button } from "../../styles/Button.styled";

export const Rejected = ({ message }: {
    message: string;
}) => {
    const { t } = useTranslation('enrollment')
    const { isTx } = useContext<any>(EnrollmentContext);

    return (
        <EnrollmentResultContent>
            <div className="text-center">
                <h1 className="mb-3">{t('Your application was not approved')}</h1>
                <p>{t(message)}</p>
                <div className="mt-4">
                    <Button
                        onClick={() => {
                            document.location.href = isTx ? `tel:8887335557` : `tel:8883556205`
                        }}>
                        Contact Us: { isTx ? '(888) 733-5557' : '(888) 355-6205' }
                    </Button>
                </div>
                <div className="mt-5">
                    <Button secondary
                        onClick={() => {
                            window.location.reload();
                        }}>
                        {t('Close the Window')}
                    </Button>
                </div>
            </div>
        </EnrollmentResultContent>
    )
}
