import { uiLogging } from "../api/api";
import { getEnrollmentRecord } from "../api/enrollment";

export const shareAsaleNew = async(id, sign) =>{

        const res = await getEnrollmentRecord(id, sign)
        const customerData = res?.response;

        const planData = JSON.parse(customerData?.plan)

        const hasSuccessfulTransactionEvent = () => {
            if (window.dataLayer) {
              for (let i = 0; i < window.dataLayer.length; i++) {
                const event = window.dataLayer[i];
                if (event.event === "successfulTransaction") {
                  return true; // Found the event
                }
              }
            }
            return false; // Event not found
        };

        // Create the tracking pixel element
        const trackingPixel = document.createElement('img');
        trackingPixel.src = `https://www.shareasale.com/sale.cfm?tracking=${id}&amount=1&merchantID=146088&transtype=sale`;
        trackingPixel.width = 1;
        trackingPixel.height = 1;
        trackingPixel.alt = 'ShareASale Tracking Pixel';

        // Append the tracking pixel to the document's body
        document.body.appendChild(trackingPixel);

        // Create the script element for the master tag
        const script = document.createElement('script');
        script.src = 'https://www.dwin1.com/62539.js';
        script.type = 'text/javascript';
        //@ts-ignore
        script.defer = 'defer';

        // Append the script to the document's body
        document.body.appendChild(script);

        if (!hasSuccessfulTransactionEvent()) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'successfulTransaction',
                orderID: id,
                rate: `${planData?.rate2000}¢`,
                term: planData?.term,
                subtotal: "1",
                planName: planData?.name
            });
            uiLogging(id, sign, "share-a-sale updated")
        }
}

export const shareAsaleNew2 = async(id, sign, page, state) =>{

    const res = await getEnrollmentRecord(id, sign)
    const customerData = res?.response;

    const planData = JSON.parse(customerData?.plan)

    const hasSuccessfulTransactionEvent = () => {
        if (window.dataLayer) {
          for (let i = 0; i < window.dataLayer.length; i++) {
            const event = window.dataLayer[i];
            if (event.event === "successfulTransaction") {
              return true; // Found the event
            }
          }
        }
        return false; // Event not found
    };
    const hasConversionEvent = () => {
        if (window.dataLayer) {
          for (let i = 0; i < window.dataLayer.length; i++) {
            const event = window.dataLayer[i];
            if (event.event === "conversion") {
              return true; // Found the event
            }
          }
        }
        return false; // Event not found
    };

    // Create the tracking pixel element
    const trackingPixel = document.createElement('img');
    trackingPixel.src = `https://www.shareasale.com/sale.cfm?tracking=${id}&amount=1&merchantID=146088&transtype=sale`;
    trackingPixel.width = 1;
    trackingPixel.height = 1;
    trackingPixel.alt = 'ShareASale Tracking Pixel';

    // Append the tracking pixel to the document's body
    document.body.appendChild(trackingPixel);

    // Create the script element for the master tag
    const script = document.createElement('script');
    script.src = 'https://www.dwin1.com/62539.js';
    script.type = 'text/javascript';
    //@ts-ignore
    script.defer = 'defer';

    // Append the script to the document's body
    document.body.appendChild(script);

    if (!hasSuccessfulTransactionEvent()) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'successfulTransaction',
            orderID: id,
            rate: `${planData?.rate2000}¢`,
            term: planData?.term,
            subtotal: "1",
            planName: planData?.name
        });
        uiLogging(id, sign, "successfulTransaction share-a-sale updated")
    }


    if(!hasConversionEvent() && (page === "autopaytx" || page === "deposittx")){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'conversion',
            page_url: window.location.href,
            referrer: document.referrer
        });
        uiLogging(id, sign, "conversion TX share-a-sale updated")
    }else if(!hasConversionEvent() && page === "confirmation"){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'conversion',
            page_url: window.location.href,
            region: state,
            referrer: document.referrer
        });
        uiLogging(id, sign, "conversion NE share-a-sale updated")
    }
}