import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./locales/en/translation.json";
import es from "./locales/es/translation.json";


const detectionOptions = {
    caches: ['cookie'],
    cookieSameSite: 'strict',
    lookupQuerystring: 'lang', // default is lng
    lookupCookie: 'next-i18next',
    order: ['querystring', 'cookie', 'header'], // IMPORTANT
}

const resources = {
    en,
    es
}

i18n
.use(initReactI18next)
.init({
    resources,
    detection: detectionOptions,
    defaultNS: "common",
    fallbackLng: "en",
});