import { CS_API_URL, ACC_KEY } from '../config'

export const saveEnrollment = async (enrollmentId, restFields, sign) => {
    // const myHeaders = new Headers()
    // myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    if (enrollmentId) formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)
    for (let i in restFields) {
        formdata.append(i, restFields[i])
    }
    
    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${CS_API_URL}enrollment/save`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getEnrollmentRecord = async (enrollmentId, sign) => {
    // const myHeaders = new Headers()
    // myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)

    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${CS_API_URL}enrollment/retrieve`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const confirmEnrollment = async (
    enrollmentId, specialPro, sign, utmContent, username, password, customerId, testSplitDeposit
) => {
    // const myHeaders = new Headers()
    // myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)
    if (utmContent) formdata.append('utm_content', utmContent)
    for (const key in specialPro) {
        if (specialPro[key]) formdata.append(key, specialPro[key])
    }
    if (customerId) formdata.append('customer_id', customerId);
    if (username) formdata.append('username', username);
    if (password) formdata.append('password', password);
    if (testSplitDeposit) formdata.append('test', testSplitDeposit);

    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${CS_API_URL}enrollment/submit`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getEnrollmentInputs = async (providerId, zipcode, utilityCode) => {
    // const myHeaders = new Headers()
    // myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('provider_id', providerId)
    formdata.append('zipcode', zipcode)
    formdata.append('utility_code', utilityCode)

    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${CS_API_URL}enrollment/input`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const saveEnrollmentPaymentInfo = async (enrollmentId, paymentResponse, sign, confirmationNumber, paidAmount) => {
    // const myHeaders = new Headers()
    // myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const agentParamIsTrue = new URLSearchParams(window.location.search).get('isAgent') === "true";
    
    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    formdata.append('response', paymentResponse)
    formdata.append('sign', sign)
    formdata.append('paid_amount', paidAmount)
    formdata.append('agent', agentParamIsTrue)
    if (confirmationNumber) {
        formdata.append('confirmation_number', confirmationNumber)
    }

    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${CS_API_URL}enrollment/icg`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchServiceSwitchOptions = async (state) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const url = `${CS_API_URL}utility/switch_type`

    const formdata = new FormData();
    formdata.append("state_id", state);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}

export const fetchBlackoutDate = async (utilityCode, available_from_date, available_until_date) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("utility_code", utilityCode);
    formdata.append("available_from_date", available_from_date);
    formdata.append("available_until_date", available_until_date);

    const url = `${CS_API_URL}enrollment/blackout_date`

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}