import { useState } from "react";
import { useTranslation } from "react-i18next";

export const PlanDesc = ({ desc }: {
    desc: string;
}) => {
    const { t } = useTranslation('enrollment');
    const [ showMore, setShowMore ] = useState(false);

    if (!desc) return null;

    return (
        <div className="plan-desc-section">
            <p className={`plan-desc ${showMore ? 'active' : ''}`} dangerouslySetInnerHTML={{__html: desc}} />
            <a href="#" onClick={(evt: any) => {
                evt.preventDefault();
                setShowMore(!showMore);
            }}>{ showMore ? t('Hide') : t('Show More') }</a>
        </div>
    )
}