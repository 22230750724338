import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    EnrollmentStepWrapper,
} from '../styles/Enrollment.styled'
import { Button } from '../styles/Button.styled'
import { fetchServiceSwitchOptions } from '../../api/enrollment'
import { EnrollmentContext } from '../../enrollment'
import { ValidateZipcode } from '../../validate_zipcode'
import { statesList } from '../../states-list'
import { fetchUtility, getAgentScript } from '../../api/api'
import { useLocation } from 'react-router'
import qs from 'qs'
import 'moment/locale/es'

const nonTxStates = statesList.filter(val => val.abbr !== 'TX');

interface Props {
    show: boolean;
    handleContinue: Function;
    handleOpenPlanModal: Function;
    isShowPlanModal: boolean;
    handleSwitchToTx: Function;
    utilityList: any;
    planIsLoaded: boolean;
    handleConfirmUsn: Function;
    handleOpenUtilityModal: Function;
    setScriptData: any;
}

interface ServiceTypeOption {
    label: string
    value: string
    desc: string
}

interface StatesOption {
    name: string
    abbr: string
}

export const Zipcode: React.FC<Props> = ({
    show,
    handleContinue,
    utilityList,
    handleOpenPlanModal,
    isShowPlanModal,
    handleSwitchToTx,
    planIsLoaded,
    handleConfirmUsn,
    handleOpenUtilityModal,
}) => {
    const {
        handleSetErrorModalContent, usnZipcode, changeZipcode,
        changeServiceAddress, changeUtilityList, selectedPlans, enrollmentTokens
    } = useContext<any>(EnrollmentContext);

    const { t, i18n } = useTranslation('enrollment');
    const location = useLocation()
    const { promocode } = qs.parse(
        location.search, {
        ignoreQueryPrefix: true,
    })
    const [serviceTypeOptions, setServiceTypeOptions] = useState<any>([]);
    const [serviceType, setServiceType] = useState<ServiceTypeOption | undefined>(undefined);
    
    const [loadingUtility, setLoadingUtility] = useState(false);
    const [utilityDetail, setUtilityDetail] = useState<any>([]);



    useEffect(() => {
        if (!utilityList || selectedPlans.length === 0) {
            setUtilityDetail([]);
        }
        else {
            let newUtilityDetail: any = [];

            const utilitiesInPlan = selectedPlans.reduce((arr: string[], item: any) => {
                return [...arr, item.utilityCode];
            }, [])

            Object.values(utilityList).forEach((utilities: any) => {
                utilities.forEach((item: any) => {
                    if (Object.values(utilitiesInPlan).includes(item.utility_code)) {
                        newUtilityDetail.push(item);
                    }
                })
            });

            setUtilityDetail(newUtilityDetail);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utilityList, selectedPlans]);


    const getUsnServiceSwitchOptions = async (state: string) => {
        await fetchServiceSwitchOptions(state).then(res => {
            let options: any = [];
            if (res) {
                const { response } = res;
                response.forEach((val: any) => {
                    options.push({
                        id: val.name,
                        label: val.name === 'standardSwitch' ? t('Standard Switch') : t('Let me choose the day'),
                        value: val.datepicker ? '2' : '-1',
                        desc: val.desc
                    })
                })

                setServiceTypeOptions(options);
                if (options.length === 1) {
                    setServiceType(options[0]);
                }
            }
        })
    }

    const searchUsnUtility = (zipcode: string) => {
        if (loadingUtility) return;
        changeZipcode('');
        setLoadingUtility(true);
        fetchUtility(zipcode, promocode).then(res => {
            setLoadingUtility(false);
            if (res && res.status === 1) {
                const { utility, state } = res.response;
                if (state) {
                    const stateId = state.state_id;
                    getAgentScript();
                    const isTx = stateId && stateId.toLowerCase().indexOf('tx') >= 0;
                    if (isTx) {
                        console.log('Texas zipcode detected!');
                        // window.location.href = `https://rates.cleanskyenergy.com:8443/rates/index?customerTypeId=7&zipcode=${zipcode}`
                        // return;
                        // !!! Temporary redirect Texas flow to CSE legacy site !!!
                        changeUtilityList(undefined);
                        handleSwitchToTx({
                            zipcode: zipcode,
                            utility: utility
                        });
                    }
                    else {
                        handleConfirmUsn(true);
                    }

                    const [...utilityWithState] = utility;
                    utilityWithState.forEach((item: any) => {
                        if (!item.state) item.state = state;
                        if (isTx) item.commodity_name = "Electric";
                    })
                    changeUtilityList(utilityWithState);
                    changeZipcode(zipcode);
                }
            }
            else {
                handleSetErrorModalContent({
                    title: t("We're sorry, CleanSky Energy currently does not service this area."),
                    desc: ' '
                });
                changeUtilityList(undefined);
            }
        })
    }

    const showInput = () => {
        return (
            <>
                <h6 className="mt-2 mb-2">{t('Zip Code')} *</h6>
                <ValidateZipcode
                    handleSearchUtility={(zipcode: string) => searchUsnUtility(zipcode)}
                    loadingUtility={loadingUtility}
                    defaultZipcode={usnZipcode}
                />
                {
                    (planIsLoaded && selectedPlans.length === 0 && usnZipcode && !isShowPlanModal) &&
                    <div className="mt-3">
                        <Button sm secondary onClick={() => handleOpenUtilityModal()}>Choose Plan</Button>
                    </div>
                }
            </>
        )
    }

    if (!show) return null
    return (
        <EnrollmentStepWrapper>
            {showInput()}
        </EnrollmentStepWrapper>
    )
}
