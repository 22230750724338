import { useEffect, useState } from "react";
import { Button } from '../../styles/Button.styled'


interface Props {
    show: boolean;
    esiid: String;
    badDebt: String;
    status: String;
    magicLink: String;
  }

const ExistingCustomer: React.FC<Props> = ({show,esiid,badDebt,status,magicLink}) =>{
    const [ isExistingCustomer, setIsExistingCustomer ] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
    
        // Initial check
        handleResize();
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Remove event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!show) return null
    return (
        <>
        <div className='container mt-5 p-4 flex' style={{borderRadius: '11px', background: '#EFF7FA', width: '100%', height: isExistingCustomer ? 'auto' : '152px'}}>
            <div className="row">
                <div className="col-md-6">
                    <div className='d-flex align-items-center mb-4'>
                        <div className='me-2' style={{color: '#FF843F', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>Warning: </div>
                        <div className='me-3' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{isExistingCustomer ? `Existing Customer`: `Service Address`} </div>
                    </div>
                    <p style={{color: '#000', fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight:'154.182%'}}>38939 Golden Willow Ct, Katy, TX 77449 </p>
                    <div className='d-flex align-items-center mb-4'>
                        <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Bad Debt:</div>
                        <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{isExistingCustomer ? `$56.23`: `None`}</div>
                        <div style={{marginTop: '-2px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect width="24" height="24" fill="#EFF7FA"/>
                                <path d="M18.7114 13.9877L17.2041 11.7874C17.1783 11.7504 17.1632 11.7069 17.1606 11.6619C17.1579 11.6168 17.1678 11.5719 17.1891 11.5321C17.2104 11.4923 17.2422 11.4592 17.2812 11.4364C17.3201 11.4136 17.3646 11.402 17.4097 11.4029H20.4181C20.463 11.4027 20.507 11.4148 20.5455 11.4377C20.584 11.4607 20.6155 11.4937 20.6366 11.5333C20.6578 11.5728 20.6678 11.6174 20.6655 11.6621C20.6632 11.7069 20.6488 11.7502 20.6238 11.7874L19.1226 13.9877C19.1001 14.0216 19.0696 14.0495 19.0337 14.0687C18.9978 14.088 18.9577 14.0981 18.917 14.0981C18.8763 14.0981 18.8362 14.088 18.8003 14.0687C18.7644 14.0495 18.7339 14.0216 18.7114 13.9877Z" fill="#617692"/>
                                <path d="M19.0003 12.0187C18.8639 12.0187 18.7332 11.9646 18.6368 11.8682C18.5403 11.7717 18.4862 11.641 18.4862 11.5046C18.4861 10.2674 18.1321 9.05606 17.4659 8.01355C16.7997 6.97103 15.8491 6.1409 14.7263 5.62114C13.6036 5.10139 12.3556 4.91373 11.1296 5.0803C9.90368 5.24687 8.75098 5.76073 7.80762 6.56121C7.75605 6.60482 7.69638 6.63785 7.63204 6.6584C7.5677 6.67895 7.49994 6.68663 7.43263 6.681C7.36532 6.67537 7.29978 6.65653 7.23975 6.62557C7.17972 6.59461 7.12638 6.55213 7.08277 6.50055C7.03915 6.44897 7.00613 6.38931 6.98557 6.32497C6.96502 6.26062 6.95734 6.19286 6.96297 6.12555C6.96861 6.05824 6.98744 5.9927 7.0184 5.93267C7.04937 5.87264 7.09185 5.8193 7.14343 5.77569C8.23638 4.85173 9.57047 4.25913 10.9888 4.0676C12.407 3.87606 13.8505 4.09356 15.1494 4.69451C16.4483 5.29545 17.5485 6.25485 18.3207 7.45983C19.0929 8.66481 19.5049 10.0653 19.5082 11.4964C19.5093 11.5641 19.497 11.6313 19.472 11.6943C19.4471 11.7572 19.4099 11.8145 19.3627 11.8631C19.3155 11.9116 19.2592 11.9503 19.197 11.977C19.1348 12.0038 19.068 12.0179 19.0003 12.0187Z" fill="#617692"/>
                                <path d="M19.0245 14.7116C18.9027 14.71 18.783 14.6791 18.6756 14.6215C18.5682 14.5639 18.4763 14.4812 18.4076 14.3805L16.8818 12.1823C16.8041 12.0688 16.7586 11.9363 16.7502 11.799C16.7418 11.6616 16.7708 11.5246 16.8341 11.4024C16.8974 11.2803 16.9926 11.1776 17.1097 11.1052C17.2267 11.0329 17.3612 10.9937 17.4987 10.9917H20.5257C20.6632 10.9937 20.7977 11.0329 20.9148 11.1052C21.0318 11.1776 21.127 11.2803 21.1903 11.4024C21.2536 11.5246 21.2826 11.6616 21.2742 11.799C21.2658 11.9363 21.2203 12.0688 21.1426 12.1823L19.6414 14.3764C19.5731 14.4775 19.4813 14.5604 19.3738 14.6181C19.2664 14.6757 19.1465 14.7064 19.0245 14.7075V14.7116ZM18.0108 12.0199L19.0245 13.4778L20.0239 12.0199H18.0108Z" fill="#617692"/>
                                <path d="M5.13168 8.83384L6.63898 11.0341C6.66478 11.0712 6.67983 11.1146 6.68248 11.1596C6.68513 11.2047 6.67526 11.2496 6.65398 11.2894C6.6327 11.3292 6.60083 11.3623 6.56189 11.3851C6.52295 11.408 6.47847 11.4196 6.43335 11.4187H3.42492C3.38009 11.4188 3.33606 11.4068 3.29756 11.3838C3.25906 11.3608 3.22755 11.3278 3.20641 11.2883C3.18526 11.2487 3.17529 11.2042 3.17756 11.1594C3.17983 11.1146 3.19426 11.0713 3.21929 11.0341L4.72864 8.83384C4.75143 8.8016 4.78163 8.77529 4.81669 8.75713C4.85176 8.73897 4.89067 8.72949 4.93016 8.72949C4.96965 8.72949 5.00857 8.73897 5.04363 8.75713C5.0787 8.77529 5.10889 8.8016 5.13168 8.83384Z" fill="#617692"/>
                                <path d="M12.0211 19.0001C10.0309 18.9979 8.12277 18.2069 6.71474 16.8004C5.30671 15.3939 4.51363 13.4867 4.50928 11.4965C4.50928 11.3602 4.56344 11.2294 4.65985 11.133C4.75626 11.0366 4.88702 10.9824 5.02336 10.9824C5.15971 10.9824 5.29046 11.0366 5.38687 11.133C5.48328 11.2294 5.53745 11.3602 5.53745 11.4965C5.54006 12.7319 5.89555 13.9408 6.56208 14.981C7.22861 16.0211 8.17843 16.8492 9.29973 17.3677C10.421 17.8862 11.6671 18.0736 12.8913 17.9078C14.1155 17.742 15.2669 17.2298 16.2098 16.4317C16.314 16.3436 16.4489 16.3005 16.5848 16.3119C16.7208 16.3233 16.8466 16.3882 16.9347 16.4924C17.0228 16.5965 17.0659 16.7314 17.0545 16.8674C17.0431 17.0033 16.9782 17.1292 16.874 17.2172C15.5179 18.3664 13.7986 18.998 12.0211 19.0001Z" fill="#617692"/>
                                <path d="M6.53272 12.0349H3.51608C3.3785 12.0329 3.24403 11.9937 3.12699 11.9213C3.00995 11.849 2.91471 11.7463 2.85141 11.6241C2.78811 11.502 2.75913 11.3649 2.76754 11.2276C2.77595 11.0903 2.82144 10.9578 2.89917 10.8443L4.4003 8.65015C4.46966 8.54794 4.56299 8.46427 4.67214 8.40643C4.78129 8.3486 4.90293 8.31836 5.02645 8.31836C5.14998 8.31836 5.27162 8.3486 5.38077 8.40643C5.48992 8.46427 5.58325 8.54794 5.65261 8.65015L7.16196 10.8484C7.24017 10.9626 7.28572 11.096 7.2937 11.2343C7.30169 11.3725 7.27181 11.5103 7.20728 11.6327C7.14276 11.7552 7.04603 11.8578 6.92753 11.9294C6.80902 12.001 6.67323 12.0389 6.53478 12.039L6.53272 12.0349ZM4.02399 11.0067H6.02275L5.0172 9.54877L4.02399 11.0067Z" fill="#617692"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                        {isMobile ?(
                                <div className='d-flex align-items-center mb-4'>
                                    <div className='me-3' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>ESIID: </div>
                                    <div style={{color: '#000', fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>100890102381249278102 </div>
                                </div>
                        ):(<>
                            <div className='d-flex align-items-center mb-4'>
                                    <div className='me-3' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>ESIID: </div>
                            </div>
                            <p style={{color: '#000', fontSize: '16px', fontStyle: 'normal', fontWeight: '600', lineHeight:'154.182%'}}>100890102381249278102 </p>
                          </>
                        )}
                    <div className='d-flex align-items-center mb-4'>
                        <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Status:</div>
                        <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '600', lineHeight:'normal'}}>{isExistingCustomer ? `Unpaid`: `Paid`}</div>
                        <div style={{marginTop: '-2px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect width="24" height="24" fill="#EFF7FA"/>
                                <path d="M18.7114 13.9877L17.2041 11.7874C17.1783 11.7504 17.1632 11.7069 17.1606 11.6619C17.1579 11.6168 17.1678 11.5719 17.1891 11.5321C17.2104 11.4923 17.2422 11.4592 17.2812 11.4364C17.3201 11.4136 17.3646 11.402 17.4097 11.4029H20.4181C20.463 11.4027 20.507 11.4148 20.5455 11.4377C20.584 11.4607 20.6155 11.4937 20.6366 11.5333C20.6578 11.5728 20.6678 11.6174 20.6655 11.6621C20.6632 11.7069 20.6488 11.7502 20.6238 11.7874L19.1226 13.9877C19.1001 14.0216 19.0696 14.0495 19.0337 14.0687C18.9978 14.088 18.9577 14.0981 18.917 14.0981C18.8763 14.0981 18.8362 14.088 18.8003 14.0687C18.7644 14.0495 18.7339 14.0216 18.7114 13.9877Z" fill="#617692"/>
                                <path d="M19.0003 12.0187C18.8639 12.0187 18.7332 11.9646 18.6368 11.8682C18.5403 11.7717 18.4862 11.641 18.4862 11.5046C18.4861 10.2674 18.1321 9.05606 17.4659 8.01355C16.7997 6.97103 15.8491 6.1409 14.7263 5.62114C13.6036 5.10139 12.3556 4.91373 11.1296 5.0803C9.90368 5.24687 8.75098 5.76073 7.80762 6.56121C7.75605 6.60482 7.69638 6.63785 7.63204 6.6584C7.5677 6.67895 7.49994 6.68663 7.43263 6.681C7.36532 6.67537 7.29978 6.65653 7.23975 6.62557C7.17972 6.59461 7.12638 6.55213 7.08277 6.50055C7.03915 6.44897 7.00613 6.38931 6.98557 6.32497C6.96502 6.26062 6.95734 6.19286 6.96297 6.12555C6.96861 6.05824 6.98744 5.9927 7.0184 5.93267C7.04937 5.87264 7.09185 5.8193 7.14343 5.77569C8.23638 4.85173 9.57047 4.25913 10.9888 4.0676C12.407 3.87606 13.8505 4.09356 15.1494 4.69451C16.4483 5.29545 17.5485 6.25485 18.3207 7.45983C19.0929 8.66481 19.5049 10.0653 19.5082 11.4964C19.5093 11.5641 19.497 11.6313 19.472 11.6943C19.4471 11.7572 19.4099 11.8145 19.3627 11.8631C19.3155 11.9116 19.2592 11.9503 19.197 11.977C19.1348 12.0038 19.068 12.0179 19.0003 12.0187Z" fill="#617692"/>
                                <path d="M19.0245 14.7116C18.9027 14.71 18.783 14.6791 18.6756 14.6215C18.5682 14.5639 18.4763 14.4812 18.4076 14.3805L16.8818 12.1823C16.8041 12.0688 16.7586 11.9363 16.7502 11.799C16.7418 11.6616 16.7708 11.5246 16.8341 11.4024C16.8974 11.2803 16.9926 11.1776 17.1097 11.1052C17.2267 11.0329 17.3612 10.9937 17.4987 10.9917H20.5257C20.6632 10.9937 20.7977 11.0329 20.9148 11.1052C21.0318 11.1776 21.127 11.2803 21.1903 11.4024C21.2536 11.5246 21.2826 11.6616 21.2742 11.799C21.2658 11.9363 21.2203 12.0688 21.1426 12.1823L19.6414 14.3764C19.5731 14.4775 19.4813 14.5604 19.3738 14.6181C19.2664 14.6757 19.1465 14.7064 19.0245 14.7075V14.7116ZM18.0108 12.0199L19.0245 13.4778L20.0239 12.0199H18.0108Z" fill="#617692"/>
                                <path d="M5.13168 8.83384L6.63898 11.0341C6.66478 11.0712 6.67983 11.1146 6.68248 11.1596C6.68513 11.2047 6.67526 11.2496 6.65398 11.2894C6.6327 11.3292 6.60083 11.3623 6.56189 11.3851C6.52295 11.408 6.47847 11.4196 6.43335 11.4187H3.42492C3.38009 11.4188 3.33606 11.4068 3.29756 11.3838C3.25906 11.3608 3.22755 11.3278 3.20641 11.2883C3.18526 11.2487 3.17529 11.2042 3.17756 11.1594C3.17983 11.1146 3.19426 11.0713 3.21929 11.0341L4.72864 8.83384C4.75143 8.8016 4.78163 8.77529 4.81669 8.75713C4.85176 8.73897 4.89067 8.72949 4.93016 8.72949C4.96965 8.72949 5.00857 8.73897 5.04363 8.75713C5.0787 8.77529 5.10889 8.8016 5.13168 8.83384Z" fill="#617692"/>
                                <path d="M12.0211 19.0001C10.0309 18.9979 8.12277 18.2069 6.71474 16.8004C5.30671 15.3939 4.51363 13.4867 4.50928 11.4965C4.50928 11.3602 4.56344 11.2294 4.65985 11.133C4.75626 11.0366 4.88702 10.9824 5.02336 10.9824C5.15971 10.9824 5.29046 11.0366 5.38687 11.133C5.48328 11.2294 5.53745 11.3602 5.53745 11.4965C5.54006 12.7319 5.89555 13.9408 6.56208 14.981C7.22861 16.0211 8.17843 16.8492 9.29973 17.3677C10.421 17.8862 11.6671 18.0736 12.8913 17.9078C14.1155 17.742 15.2669 17.2298 16.2098 16.4317C16.314 16.3436 16.4489 16.3005 16.5848 16.3119C16.7208 16.3233 16.8466 16.3882 16.9347 16.4924C17.0228 16.5965 17.0659 16.7314 17.0545 16.8674C17.0431 17.0033 16.9782 17.1292 16.874 17.2172C15.5179 18.3664 13.7986 18.998 12.0211 19.0001Z" fill="#617692"/>
                                <path d="M6.53272 12.0349H3.51608C3.3785 12.0329 3.24403 11.9937 3.12699 11.9213C3.00995 11.849 2.91471 11.7463 2.85141 11.6241C2.78811 11.502 2.75913 11.3649 2.76754 11.2276C2.77595 11.0903 2.82144 10.9578 2.89917 10.8443L4.4003 8.65015C4.46966 8.54794 4.56299 8.46427 4.67214 8.40643C4.78129 8.3486 4.90293 8.31836 5.02645 8.31836C5.14998 8.31836 5.27162 8.3486 5.38077 8.40643C5.48992 8.46427 5.58325 8.54794 5.65261 8.65015L7.16196 10.8484C7.24017 10.9626 7.28572 11.096 7.2937 11.2343C7.30169 11.3725 7.27181 11.5103 7.20728 11.6327C7.14276 11.7552 7.04603 11.8578 6.92753 11.9294C6.80902 12.001 6.67323 12.0389 6.53478 12.039L6.53272 12.0349ZM4.02399 11.0067H6.02275L5.0172 9.54877L4.02399 11.0067Z" fill="#617692"/>
                            </svg>
                        </div>
                    </div>
                </div>
                {(isExistingCustomer) && <div>
                    <div className='me-2' style={{color: '#000', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight:'154.182%'}}>Send Magic Link for Payment:</div>
                    <div className='mt-2 d-flex'>
                            <input className='me-4' type='text' style={{borderRadius: '10px', textTransform: 'capitalize', border: '1px solid #CED4DA', width: '557px', height:'38px'}} placeholder={`enter email address`} />
                            <a type='submit'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                    <circle cx="16.5" cy="16.5" r="16.5" fill="#617692"/>
                                    <path d="M7.47839 16.571C6.81763 16.9176 6.84504 17.4253 7.53778 17.6995L9.59032 18.5116C10.2836 18.7868 11.3327 18.6366 11.9204 18.178L20.8336 11.1489C21.4195 10.6881 21.4823 10.756 20.9735 11.3008L13.9273 18.8428C13.4167 19.3859 13.5675 20.053 14.2631 20.3219L14.5035 20.4156C15.1991 20.6846 16.3327 21.1363 17.0238 21.4173L19.3002 22.3442C19.9918 22.6252 20.7308 22.294 20.9272 21.5744L24.3967 8.8217C24.5925 8.10212 24.2122 7.79658 23.5514 8.14267L7.47839 16.571Z" fill="white"/>
                                    <path d="M13.1111 24.9198C13.0705 25.0415 14.5148 22.8285 14.5148 22.8285C14.9232 22.2048 14.6913 21.4607 14.0014 21.1786L12.4263 20.5338C11.7364 20.2517 11.4058 20.5829 11.6913 21.2722C11.6913 21.2722 13.1528 24.7948 13.1111 24.9198Z" fill="white"/>
                                </svg>
                            </a>
                    </div>
                </div>}
            </div>
        </div>
        </>
      )
}

export default ExistingCustomer;