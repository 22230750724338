import { useTranslation } from "react-i18next";
import { Button } from "../../styles/Button.styled";
import { EnrollmentResultContent } from "../../styles/Enrollment.styled";
import { useEffect } from "react";
import isAdmin from "../../../utils/isAdmin";

export const PendingDeposit = ({ additional, getScriptForAgent }: {
    additional: any;
    getScriptForAgent?: any;
}) => {
    const { t } = useTranslation('enrollment')

    useEffect(()=>{
        (async()=>{
            await isAdmin() && getScriptForAgent("7")
        })()
    },[])

    const getAdditionalContent = () => {
        return additional.map((val: any, idx: number) => {
            return (
                <div className="mb-2" key={idx}>
                    <Button
                        key={idx}
                        onClick={() => {
                            if (val.value.indexOf('http') === 0) {
                                if (typeof window !== undefined) {
                                    window.open(val.value, '_blank')
                                }
                            } else {
                                document.location.href = `tel:${val.value}`
                            }
                        }}>
                        {t(val.label)}
                    </Button>
                </div>
            )
        })
    }

    return (
        <EnrollmentResultContent>
            <div className="text-center">
                <h1 className="mb-3">{t("We're Almost There!")}</h1>
                <p>{t('Thank you for your patience. In order to complete your enrollment, please pay your deposit by clicking the button below.')}</p>
                <div className="mt-5">{getAdditionalContent()}</div>
            </div>
            <div className="mt-5">
                <h5 className="mb-2">{t('Why Should I Pay The Deposit?')}</h5>
                <p>{t('After review of your credit history, a refundable deposit is required to initiate service. Your payment information will only be used to process the deposit and will not be used for any other purposes.')}</p>
            </div>
        </EnrollmentResultContent>
    )
}
