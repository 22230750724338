export function getCommodityIconSrc(type) {
    const lowerCaseType = type.toLowerCase();

    switch (lowerCaseType) {
        case 'wind': return "/images/icon_wind.png";
        case 'easy': return "/images/breathe_easy.png";
        case 'solar': return "/images/icon_solar.png";
        case 'renewable': return "/images/icon_green.png";
        case 'carbon offset': return "/images/icon_gas.png";
        default: return "";
    }
}