import { PureComponent, useEffect, useState } from "react";
import { PlanProps } from "../types";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid,
    ResponsiveContainer, ReferenceLine
} from 'recharts';
import moment from "moment";
import { fetchRateCompare } from "../api/api";
import { AsideBox, AsideBoxBody, AsideBoxHeader } from "./styles/Enrollment.styled";
import { useTranslation } from "react-i18next";

interface ChartDataTypes {
    id: string;
    value: number;
    label: string;
}

export const RateComparisonTx = ({ product, utility, utilityList }: {
    product: PlanProps[];
    utilityList: any;
    utility: any;
}) => {
    const { t } = useTranslation('enrollment');
    const [ chartData, setChartData ] = useState<ChartDataTypes[]>([]);
    const [ marketPrice, setMarketPrice ] = useState('');
    const [ plan, setPlan ] = useState<any>(undefined);

    function customRound(number: number) {
        if (Number.isInteger(number)) {
            return number;
        } else {
            const integerPart = Math.floor(number);
            const decimalPart = number - integerPart;
    
            if (decimalPart < 0.5) {
                return integerPart;
            } else {
                return integerPart + 1;
            }
        }
    }

    function roundToOne(number: number) {
        try{
            return Number(Number(number).toFixed(1))
        }catch(err){
            return number
        }
    }
    
    useEffect(() => {
        if (!product) return;
        const electricPlan = product.find((val: any) => val.commodity_name.toLowerCase() === 'electric');
        setPlan(electricPlan);
    }, [product])

    useEffect(() => {
        if (!plan || !utility) return;

        const electricUtilityCode = utility['Electric'];

        if (electricUtilityCode) {
            const { contract_term } = plan;
    
            fetchRateCompare(electricUtilityCode, contract_term).then(res => {
                if (res && parseInt(res.status) === 1) {
                    setMarketPrice(res.response.total_average_price);
                }
            })
        }

    }, [plan, utility])

    useEffect(() => {
        if (!plan || !marketPrice) return;

        setChartData([{
            id: '0',
            value: roundToOne(parseFloat(plan.price2000)),
            label: 'Clean Sky Energy'
        }, {
            id: '1',
            value: roundToOne(parseFloat(marketPrice)),
            label: 'Average'
        }
        ])
    }, [plan, marketPrice])

    if(customRound((1 - chartData[0]?.value / chartData[1]?.value) * 100) < 5) return null;
    
    if (chartData.length === 0 || !product || !utilityList) return null;
    
    const findUtility = utilityList['Electric'].find((item: any) => (item.utility_code === utility['Electric'] || item.id_utility == utility['Electric']));
    
    if (!plan) return null;
    
    const savingPercentage = Math.round((parseFloat(marketPrice) - plan.price2000) / plan.price2000 * 10000) / 100;
    
    if (savingPercentage <= 0) return null;
    return (
        <AsideBox whiteBg className="mt-4">
            <AsideBoxHeader>
                <h4 className="mb-2" style={{fontSize:20,fontWeight:700}}>{t('Start Saving Today')}</h4>
                <h5 className="d-flex align-items-center">
                    {t('Total Savings')}: <span className="ms-2" style={{color:'#00b7f1'}}>{customRound(savingPercentage)}%</span>
                </h5>
            </AsideBoxHeader>
            <AsideBoxBody>
            <div style={{height:130}}>
                <ResponsiveContainer>
                    <BarChart
                        data={chartData}
                        margin={{top: 30, bottom: 20}}

                    >
                        <XAxis dataKey="id" stroke="#bbb" tick={ <CustomizedTick t={t} /> } />
                        <YAxis
                            hide={true}
                            domain={['dataMin -1', 'dataMax']}
                        />
                        <CartesianGrid vertical={false} strokeDasharray="2" />
                        <Bar dataKey="value"
                            isAnimationActive={true}
                            maxBarSize={80}
                            shape={BarWithTopBorder()}
                            animationDuration={2000}
                            label={ <CustomizedLabel /> }
                        />
                        {
                            chartData[0] &&
                            <ReferenceLine
                                y={chartData[0].value}
                                stroke="#FFAF19"
                                strokeDasharray="5 3"
                                strokeWidth={1}
                            />
                        }
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <p style={{fontSize:12,color:'#999'}} className="text-center">{t('Last scan date')}: {moment().subtract(1, 'days').format('MM/DD/YYYY')}</p>
            <p style={{fontSize:12,color:'#999'}} className="text-center mb-0">
                {t('Savings based on ##TERM## month term at 2,000 kWh per month in ##UTILITY## TDU.').replace(/##TERM##/, plan.contract_term).replace(/##UTILITY##/, findUtility?.name)}
            </p>
            </AsideBoxBody>
        </AsideBox>
    )
}


const BarWithTopBorder = () => {
	return (props: any) => {
		const { x, y, width, height, id } = props;

        let isHost = id === '0' ? true : false;
		return (
			<g className={`bar-cell ${isHost ? 'special' : ''}`}>
				<rect x={x} y={y} width={width} height={height} stroke="none" className="bar" rx="2" ry="2" />
				<rect x={x} y={y} width={width} height={3} stroke="none" className="bar-top" rx="2" ry="2" />
			</g>
		)
	}
}


interface CustomizedTickProps {
    t: any
}
class CustomizedTick extends PureComponent<CustomizedTickProps> {
    render() {
        const { x, y, payload, width, visibleTicksCount, t }: Readonly<any> = this.props;
        const maxWidth = 125;
        const _width = width / visibleTicksCount > maxWidth ? maxWidth : width / visibleTicksCount;
        if (payload.value === 0 || payload.value === 'auto') return null;
        
        return (
            <g transform={`translate(${x},${y})`}>
                {
                    payload.value === '0' ? 
                    <image
                        x={_width / 2 * -1}
                        y={0}
                        xlinkHref={`/images/logo.svg`}
                        width={_width}
                    /> :
                    <foreignObject
                        x={-60}
                        y={0}
                        fill='#666'
                        fontSize={13}
                        width="120px"
                        height="40px"
                    ><span style={{display:'inline-block',maxWidth:120,lineHeight:1,textAlign:'center',width:'100%'}}>{t('Market Average')}</span></foreignObject>
                }
            </g>
        )
    }
}

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, value, width }: Readonly<any> = this.props;
        return (
            <text
                x={x + width / 2}
                y={y}
                dy={-8}
                fill='#3a3a3a'
                fontSize={width / 5}
                fontWeight="700"
                textAnchor="middle"
            >
                {value}¢
            </text>
        )
    }
}