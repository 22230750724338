import axios from "axios";

const getIpAddress = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const publicIpAddress = response.data.ip;
        return publicIpAddress;
    } catch (error) {
        return "";
    }
};

export default getIpAddress;