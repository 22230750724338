import { useEffect, useState } from "react"
import type { RadioChangeEvent } from 'antd';
import { Radio, Card, Tooltip } from 'antd';
import { Button } from "../../styles/Button.styled";
import { useNavigate } from 'react-router-dom';
import { EnrollmentResultContent } from "../../styles/Enrollment.styled";
import { Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import styled from 'styled-components';
import { fetchIsWhitelisted, getDepositConfig, uiLogging } from "../../../api/api";
import { InfoCircleOutlined } from "@ant-design/icons";
import hubspotApiCalls from "../../../utils/hubspotApiCalls";
import urlHasParam from "../../../utils/urlHasParam";


interface paymentTypeOptionInterface {
    id?: string
    label: string
    value: string
    desc: string
    img: string
}

 const CardNew = styled.div`
    border-radius: 12px;
    border: 1px solid #ddd;
    background-color: #EFF7FA;
    padding: 24px;
    margin-bottom: 24px;

    &:hover {
        border: 1px solid #00ACE7;
    }
`



const DepositOptions = ({ additional, name, enrollmentTokens, selectedPlans, showFinalPage }: {
    additional: any;
    name: string;
    enrollmentTokens: any;
    selectedPlans: any;
    showFinalPage?: any
}) =>{

    const navigate = useNavigate();
    const [serviceType, setServiceType] = useState<paymentTypeOptionInterface | undefined>(undefined)
    const [ showDepositWaiverPopup, setShowDepositWaiverPopup ] = useState(false);
    const { t, i18n } = useTranslation('enrollment')
    const [depositConfig, setDepositConfig]= useState<Object|any>()

    useEffect(()=>{
        (async()=>{
            try{
                const res = await getDepositConfig()
                if(res){
                    setDepositConfig(JSON.parse(res?.EsiidESO?.ConfigData))
                }
                if(additional[0]?.final === "true"){
                    if(additional[0]?.split_payment === "true"){
                        navigate(`/splitdepositsuccess${window.location.search}`, {state: {
                            pagename: "SplitDepositSuccess",
                            selectedPlans: selectedPlans,
                            enrollmentTokens: enrollmentTokens,
                            depositConfig: JSON.parse(res?.EsiidESO?.ConfigData),
                        }})
                    }else{
                        navigate(`/depositsuccess${window.location.search}`, {state: {
                            pagename: "DepositSuccess",
                            selectedPlans: selectedPlans,
                            enrollmentTokens: enrollmentTokens,
                            depositConfig: JSON.parse(res?.EsiidESO?.ConfigData),
                        }})
                    }
                }else{
                    const url = new URL(window.location.href);
                    const idParam = url.searchParams.get('id');
                    const signParam = url.searchParams.get('sign');

                    const urlSearchParams = new URLSearchParams(window.location.search)
                    const is_magic_link = urlSearchParams.has("sign")
                    let _resp = await fetchIsWhitelisted(enrollmentTokens?.enrollment_id || idParam)
                    if(is_magic_link && _resp?.whitelist_flag == "1"){
                        await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "fraud_whitelisted_deposit_visited", window.location.href, "Deposit Options Screen")
                        await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `Hubspot API call - Fraud whitelisted deposit visited` )
                    }else{
                        await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "deposit_visited", `${window.location.href}${urlHasParam(window.location.href)?"&":"?"}id=${enrollmentTokens?.enrollment_id}&name=${name?.trim()?.replace(/ /g, '%20')}&status=20.E01&isTx=${true}&sign=${enrollmentTokens?.sign}`, "Deposit Options Screen")
                        await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `Hubspot API call - Deposit visited`)
                    }
                }
            }catch(err){
    
            }
        })()
    },[])

    const paymentTypeOptions = [
        {
            id: 'pay-deposit',
            label: 'Pay Deposit Heading',
            value: '1',
            desc: 'Pay deposit description',
            img: '/images/icon-bank.png'
        },
        {
            id: 'split-deposit',
            label: 'Split Deposit Heading',
            value: '2',
            desc: `Split deposit description`,
            img: '/images/icon-mouse.png'
        },
        // {
        //     id: 'deposit-waiver',
        //     label: 'Submit Deposit Waiver',
        //     value: '3',
        //     desc: 'Upload eligible deposit waiver documents. View qualifications',
        //     img: '/images/icon-file-download.png'
        // }
    ]
    
    function findPaymentOptionByValue(value: string) {
        for (let i = 0; i < paymentTypeOptions.length; i++) {
            if (paymentTypeOptions[i].value === value) {
                return paymentTypeOptions[i];
            }
        }
        return undefined; // Return null if no matching value is found
    }



    const handleNextScreen = () => {
        switch(serviceType?.value){
            case '1':
                navigate(`/deposit${window.location.search}`, {state: {
                    pagename: "PendingDepositTx",
                    additional:additional,
                    name:name,
                    enrollmentTokens:enrollmentTokens,
                    selectedPlans:selectedPlans,
                    showFinalPage:showFinalPage,
                    depositConfig: depositConfig,
                    split: "true"
                }}) 
                break;
            case '2':
                navigate(`/splitdeposit${window.location.search}`, {state: {
                    pagename: "SplitDepositTx",
                    additional:additional,
                    name:name,
                    enrollmentTokens:enrollmentTokens,
                    selectedPlans:selectedPlans,
                    showFinalPage:showFinalPage,
                    depositConfig: depositConfig
                }}) 
                break;
            case '3':
                navigate(`/depositwaiver${window.location.search}`, {state: {
                    pagename: "DepositWaiver",
                    additional:additional,
                    name:name,
                    enrollmentTokens:enrollmentTokens,
                    selectedPlans:selectedPlans,
                    showFinalPage:showFinalPage
                }}) 
                break;
        }
    }

    const handleCardClick = (val: string) =>{
        let value = findPaymentOptionByValue(val)
        setServiceType(value)
    }
    

    return  <div>
            <EnrollmentResultContent>
                <div className="text-center">
                    <h1 className="mb-3">{t("Deposit Options")}</h1>
                    <p>{t('Thank you for enrolling with CleanSky Energy. A deposit is required to complete enrollment. Please select from the following options below.')}</p>
                </div>
                {/* <div className="mt-5">
                    <h5 className="mb-2">{t('Why Should I Pay The Deposit?')}</h5>
                    <p>{t('After review of your credit history, a refundable deposit is required to initiate service. Your payment information will only be used to process the deposit and will not be used for any other purposes.')}</p>
                </div> */}
            </EnrollmentResultContent>
                <br/>
                <p style={{ fontSize: 18 }}><b>{t('Deposit Amount')}: <span style={{ fontSize: 20 }}>${depositConfig?.deposit_suggested_amount? Number(depositConfig?.deposit_suggested_amount).toFixed(2):`195.00`}</span>&nbsp;&nbsp;<a href="javascript:;" style={{color:'#26B7EA',fontSize:17}} onClick={() => setShowDepositWaiverPopup(true)}>{t('Need a deposit alternative?')}</a></b></p>
                <br/>
                <Radio.Group onChange={(val: any) => {
                        let value = findPaymentOptionByValue(val.target.value)
                        setServiceType(value)
                    }} value={serviceType?.value}>
                         <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {paymentTypeOptions.map((item,index) => (
                                <CardNew
                                    key={item.id}
                                    style={{ flex: '1 0 calc(50% - 20px)', margin: '0 10px 20px', cursor: 'pointer' }}
                                    onClick={() => handleCardClick(item.value)}
                                >
                                    <Radio value={item.value} >
                                        <div style={{ fontSize: '18px' }}>{t(item.label, {amount: `$${item.label === "Pay Deposit Heading" ? Number(depositConfig?.deposit_suggested_amount).toFixed(2) : Number(depositConfig?.deposit_installment_amount[0]).toFixed(2)}`})}</div>
                                    </Radio>
                                    <div style={{ fontSize: '14px', marginTop: '10px' }}>
                                        {t(item.desc, { amount: `$${Number(depositConfig?.deposit_installment_amount[0]).toFixed(2)}` })}
                                        {/* {item.id === 'split-deposit' && <Tooltip title="Pay the deposit in two installments, first payment is due today for dollar hundred and the second payment will be built as part of your first bill.">
                                            <InfoCircleOutlined style={{ marginLeft: '5px' }} rev={undefined} />
                                        </Tooltip>} */}
                                    </div>
                                </CardNew>
                            ))}
                        </div>
                </Radio.Group>
                <br/>
                <br/>
                <Modal id="deposit-waiver-modal" size="lg" centered show={showDepositWaiverPopup} onHide={() => setShowDepositWaiverPopup(false)}>
                <Modal.Header closeButton>
                    <h4 className="pt-3">{t('Alternatives to Paying a Deposit')}</h4>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine1">
                            We may be able to waive your deposit if you qualify under any of the following, please call us at <a href="tel:8887335557">(888) 733-5557</a> to learn more about these options.
                        </Trans>
                    </p>
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine2" components={{2: <b />}}>
                            <b>Letter of credit from a previous electric provider</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant:
                        </Trans>
                    </p>
                    <ul>
                        <li>{t('Has been a customer of any retail electric provider or an electric utility within the two years prior to the request for electric service;')}</li>
                        <li>{t('Is not delinquent in payment of any such electric service account; and')}</li>
                        <li>{t('During the last 12 consecutive months of service was not late in paying a bill more than once.')}</li>
                    </ul>
                    <br />
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine3" components={{3: <b />}}>
                            <b>65 or Older</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant is 65 years of age or older and the customer is not currently delinquent in payment of any electric service account.
                        </Trans>
                    </p>
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine4" components={{4: <b />, 6: <a href="tel:1-800-681-1950" />}}>
                            <b>Victim of Family Violence</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant has been determined to be a victim of family violence as defined in the Texas Family Code §71.004, by a family violence center as defined in Texas Human Resources Code §51.002, by treating medical personnel, by law enforcement personnel, by the Office of a Texas District Attorney or County Attorney, by the Office of the Attorney General, or by a grantee of the Texas Equal Access to Justice Foundation. This determination shall be evidenced by submission of a certification letter from the Texas Council of Family Violence. The certification letter may be submitted directly to CleanSky Energy by faxing a copy to <a href="tel:1-800-681-1950">1-800-681-1950</a> toll-free.
                        </Trans>
                    </p>
                    {/* <p>
                        <Trans i18nKey="enrollment:depositWaiverLine5" components={{5: <b />}}>
                            <b>Letter of Guarantee</b> – The guarantor must be a current CleanSky Energy customer and remain a customer for the same term as the applicant, have no past due balance, and have no more than one late payment in a 12-month period during the term of the guarantee agreement.
                        </Trans>
                    </p> */}
                </Modal.Body>
            </Modal>
                <br/>
                <br/>
                <div style={{display:"flex",justifyContent:"right",width:"100%"}}>
                    <Button type="button" onClick={() => handleNextScreen()} disabled={serviceType === undefined} data-test-id="cta">
                        {t('Continue')}
                    </Button>
                </div>
                <style>
                    {`@media (max-width: 768px) {
                        .card-container {
                            flex-direction: column;
                        }
                        }
                        `}
                </style>
            </div>
}

export default DepositOptions;