import React from 'react'

export const AgentFraudScreen = () => {
  return (
    <>
    <div className='container mb-5 pt-3 flex' style={{borderRadius: '11px', background: '#FEEFEF', width: '100%', border:'3px solid #E9635A'}}>
        <div className="col-md-12">
            <div className=" mb-3">
                <div style={{borderRadius:"8px",background:"#ffffff",padding:"4px 8px",width:"min-content",display:"flex",alignItems:"center"}}>
                    <img src="../images/exclaimation.png" width="24px" height="24px" />
                    <span style={{color: '#E9635A', fontSize: '14px', fontStyle: 'normal', fontWeight: '700', lineHeight:'normal', whiteSpace:'nowrap', letterSpacing:'1.82px', marginLeft:'7px'}}>WARNING</span>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div>
                    <p style={{ color: '#254066', fontSize: '14px', fontWeight: '400', lineHeight: '1.5' }}>
                        <span style={{ display: 'block', color:'#F44545' }}>Fraud Alert has been triggered because of active customer with CleanSky Energy. </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
